/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-cycle */
import React, {
  FormEvent, useEffect, useMemo, useState,
} from 'react';
import {
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
  MdArrowBack, MdClose, MdOutlineUploadFile,
} from 'react-icons/md';
import { DropzoneAreaBase, FileObject } from 'material-ui-dropzone';
import { formatBytes } from '../../../../utils/formatBytes';
import { EsicData } from '../..';
import { getItemEsicData, getListSubjectsEsic } from '../../../../services/esic';
import getCookie from '../../../../utils/getCookies';
import { Meta } from '../../../Home/types/meta';

export interface SubjectProps {
  id: number;
  titulo: string;
  tipo_manifestacao: number;
}

export interface TypeSelectedProps {
  id: number;
  value: string;
}
interface Params {
  protocolo: string;
  tipo: string;
}

type ListTypeDemand = 'Registro Inicial' | 'Pedido Complementar' | 'Recurso' | 'Reclamação'| 'Recurso (última instância)';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface ListSubjectsProps extends Meta {
  results: SubjectProps[];
}

interface DescriptionProps {
  controlStep: (action: 'prev' | 'next') => void;
  changeEsicData: (data: EsicData) => void;
}

const listTypes = [
  {
    id: 0,
    value: 'Registro Inicial',
  },
  {
    id: 1,
    value: 'Pedido Complementar',
  },
  {
    id: 2,
    value: 'Recurso',
  },
  // {
  //   id: 3,
  //   value: 'Recurso (última instância)',
  // },
  {
    id: 4,
    value: 'Reclamação',
  },
];

export default function Description({
  controlStep,
  changeEsicData,
}: DescriptionProps): JSX.Element {
  const useStyles = makeStyles((theme: Theme) => ({
    boxStepContent: {
      padding: '0px 10px',
      marginTop: 48,
    },
    actionTitle: {
      fontSize: theme.typography.pxToRem(24),
      fontWeight: 500,
      textAlign: 'center',
      color: '#1F2937',
      [theme.breakpoints.down(660)]: {
        padding: '0px 10px',
        fontSize: theme.typography.pxToRem(18),
      },
    },
    grid: {
      marginTop: 41,
      marginBottom: 56,
      [theme.breakpoints.down(660)]: {
        marginTop: 32,
        marginBottom: 50,
      },
    },
    form: {
      width: '100%',
    },
    boxActions: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: 24,
      paddingBottom: '56px',
      [theme.breakpoints.down(660)]: {
        flexDirection: 'column',
        gap: 30,
      },
    },
    warning: {
      fontSize: theme.typography.pxToRem(14),
      color: '#4F5766',
      fontWeight: 400,
    },
    boxButton: {
      display: 'flex',
      alignItems: 'center',
      gap: 16,
      [theme.breakpoints.down(660)]: {
        width: '100%',
      },
    },
    button: {
      padding: '12px 43px',
      border: 'none',
      borderRadius: 4,
      textTransform: 'uppercase',
      fontSize: theme.typography.pxToRem(16),
      cursor: 'pointer',
      fontWeight: 500,
      fontFamily: 'Roboto',
      backgroundColor: theme.palette.primary.main,
      [theme.breakpoints.down(660)]: {
        width: '100%',
        padding: '12px 0px',
      },
    },
    forwardButton: {
      color: '#fff',
      backgroundColor: theme.palette.primary.main,
    },
    backButton: {
      display: 'flex',
      alignItems: 'center',
      gap: 11,
      padding: ' 8px, 22px, 8px, 16px',
      border: 'none',
      backgroundColor: '#FFFFFF',
      color: '#333',
      [theme.breakpoints.down(660)]: {
        justifyContent: 'center',
      },
    },
    icon: {
      width: 18,
      height: 18,
      color: '#1F2937',
    },
    borderError: {
      border: '1px solid #F03D3D',
    },
    boxDropzone: {
      marginTop: 24,
      marginBottom: 39,
      [theme.breakpoints.down(660)]: {
        marginBottom: 24,
      },
    },
    dropzone: {
      minHeight: 58,
      backgroundColor: '#F6F8FC',
      width: '100%',
      maxWidth: 220,
      height: 56,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    dropzoneIcon: {
      display: 'none',
    },
    dropzoneText: {
      color: theme.palette.primary.main,
      fontSize: theme.typography.pxToRem(15),
      fontWeight: 500,
      textTransform: 'uppercase',
      margin: '0px 0px',
    },
    document: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      border: '1px solid #DAE1EF',
      padding: 0,
      borderRadius: 4,
      height: 77,
      marginBottom: 24,
    },
    main: {
      display: 'flex',
      alignItems: 'center',
      gap: 20,
      margin: '0px 20px',
    },
    documentName: {
      fontSize: theme.typography.pxToRem(16),
      fontStyle: 'normal',
      fontWeight: 400,
      color: '#1F2937',
    },
    documentLegend: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 400,
      color: '#1F29378F',
    },
    contentIcon: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 40,
      height: 40,
      borderRadius: '50%',
      backgroundColor: '#05339614',
      // marginRight: 16,
    },
    documentIcon: {
      width: 24,
      height: 24,
      color: '#053396',
    },
    contentInfo: {
      display: 'flex',
      flexDirection: 'column',
    },
    removeDoc: {
      border: 'none',
      backgroundColor: 'transparent',
      borderLeft: '1px solid #E1E6EF',
      width: 50,
      cursor: 'pointer',
    },
    removeIcon: {
      width: 24,
      height: 24,
    },
    colorInputText: {
      '&$cssFocused $notchedOutline': {
        borderColor: theme.palette.primary.main,
      },
      '&:hover $notchedOutline': {
        borderColor: theme.palette.primary.main,
      },
    },
    textLabel: {
      color: '#333',
    },
    cssLabel: {
      color: '#333',
    },
    cssFocused: {
      color: '#333',
    },
    cssOutlinedInput: {
      '&$cssFocused $notchedOutline': {
        borderColor: `${theme.palette.primary.main} !important`,
      },
    },
    notchedOutline: {
      borderWidth: '1px',
    },
    checkBoxStyle: {
      color: theme.palette.primary.main,
    },
    menuItem: {
      color: theme.palette.primary.main,
      backgroundColor: '#fff',
      '&:hover': {
        backgroundColor: ' #fff',
        color: theme.palette.primary.main,
      },
      '&.Mui-selected': {
        backgroundColor: '#d4d4d4',
        color: theme.palette.primary.main,
        '&:hover': {
          backgroundColor: '#d4d4d4',
          color: theme.palette.primary.main,
        },
      },
    },
    select: {
      '&:before': {
        borderColor: 'white',
      },
      '&:after': {
        borderColor: 'white',
      },
      '&:not(.Mui-disabled):hover::before': {
        borderColor: 'white',
      },
    },
    iconTeste: {
      fill: 'white',
    },
    root: {
      '& label.Mui-focused': {
        color: theme.palette.primary.main,
      },
      '& .MuiFormControl-root': {
        '& .MuiInputLabel-outlined': {
          color: '#333',
        },
        '& .MuiIconButton-root': {
          color: '#333',
        },
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: theme.palette.primary.main,
        },
        '& label': {
          color: theme.palette.primary.main,
        },
        '&:hover fieldset': {
          borderColor: theme.palette.primary.main,
        },
        '&.Mui-focused fieldset': {
          borderColor: theme.palette.primary.main,
        },
        '& .MuiSelect-iconOutlined': {
          color: '#333',
          height: 24,
          width: 24,
        },
      },
    },
  }));
  const classes = useStyles();
  const params: Params = useParams();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [listSubjects, setListSubjects] = useState<any[]>([]);
  const [subject, setSubject] = useState<SubjectProps>();
  const [description, setDescription] = useState('');
  const [complaint, setComplaint] = useState<string>('');
  const [complementaryOrder, setComplementaryOrder] = useState<string>('');
  const [resource, setResource] = useState<string>('');
  const [protocol, setProtocol] = useState('');
  const [files, setFiles] = useState<FileObject[]>([]);
  const [typeSelected, setTypeSelected] = useState<TypeSelectedProps>();
  const [errorMessage, setErrorMessage] = useState<string | null>();
  const [loadingProtocol, setLoadingProtocol] = useState(false);
  const [completedSubject, setCompletedSubject] = useState<boolean>(false);

  const getSubjects = async () => {
    const { data } = await getListSubjectsEsic();

    const listWithoutItemNotInformed = data?.filter((item) => item?.titulo !== 'Não informado');

    setListSubjects(listWithoutItemNotInformed);
  };

  const getItemWithProtocol = async (protocol_number: string) => {
    if (protocol_number?.length < 14 && !typeSelected) return;
    const token: string | null = getCookie('gov_access_token_sso');
    try {
      setLoadingProtocol(true);
      const { data } = await getItemEsicData(token, protocol_number, typeSelected?.value);
      setDescription(data?.descricao);
      const subjectFound = listSubjects.find((value) => value.titulo === data.assunto);

      if (subjectFound) {
        setSubject(subjectFound);
        setCompletedSubject(true);
      } else {
        setCompletedSubject(false);
      }
    } catch (error) {
      setErrorMessage(error?.response?.data?.message);
    } finally {
      setLoadingProtocol(false);
    }
  };

  useEffect(() => {
    if (params.tipo) {
      setProtocol(params.protocolo);
      if (params?.tipo === 'Recurso') {
        setTypeSelected({ id: 2, value: params?.tipo });
        if (typeSelected && listSubjects.length > 0) {
          getItemWithProtocol(params?.protocolo);
        }
      } else if (params?.tipo === 'Recurso-ultima-instancia') {
        setTypeSelected({ id: 3, value: 'Recurso (última instância)' });
        if (typeSelected && listSubjects.length > 0) {
          getItemWithProtocol(params?.protocolo);
        }
      }
    }
  }, [params, listSubjects]);

  const handleCreateSubject = (titulo: string) => {
    setSubject(listSubjects.find((value) => value.titulo === titulo));
  };

  const handleCreateType = (titulo: string) => {
    if (!protocol.length) {
      setErrorMessage(null);
    }
    setTypeSelected(listTypes.find((value) => value.value === titulo));
  };

  const handleSetProtocol = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.value?.length <= 16) {
      setProtocol(event.target.value);
    }

    if (errorMessage?.length > 0) {
      setErrorMessage(null);
    }
    setDescription('');
    setSubject(undefined);
  };

  const removeFile = (file: FileObject) => {
    const fileFilter = files.filter(
      (fileFiltering) => fileFiltering.data !== file.data,
    );
    setFiles(fileFilter);
  };

  const getValueComplements = (identifier_label: ListTypeDemand) => {
    switch (identifier_label) {
      case 'Pedido Complementar':
        return complementaryOrder;
      case 'Reclamação':
        return complaint;
      case 'Recurso':
        return resource;
      case 'Recurso (última instância)':
        return resource;
      default:
        break;
    }
  };

  const createDescriptionData = (event: FormEvent) => {
    event.preventDefault();
    changeEsicData({
      assunto: subject,
      conteudo: description,
      canal_entrada: 'Portal',
      arquivos: files,
      tipo_demanda: typeSelected?.value,
      protocolo_relacionado: typeSelected?.value !== 'Registro Inicial' ? protocol : null,
      complemento: getValueComplements(typeSelected?.value as ListTypeDemand),
    });
    controlStep('next');
  };
  const isNotPermittedNextStep = useMemo(() => (!subject || description.length === 0
    || !typeSelected?.value.length
    || (typeSelected?.value === 'Pedido Complementar' && complementaryOrder.length === 0)
    || (typeSelected?.value === 'Recurso' && resource.length === 0)
    || (typeSelected?.value === 'Recurso (última instância)' && resource.length === 0)
    || (typeSelected?.value === 'Reclamação' && complaint.length === 0)),
  [subject, description, typeSelected, complementaryOrder, resource, complaint]);

  useEffect(() => {
    getSubjects();
  }, []);

  return (
    <Box className={classes.boxStepContent}>
      <Typography className={classes.actionTitle}>
        Descreva abaixo o conteúdo do seu pedido
      </Typography>
      {listSubjects?.length > 0 ? (
        <form onSubmit={createDescriptionData}>
          <Grid container spacing={2} className={classes.grid}>
            <Grid item xs={(typeSelected && typeSelected?.value !== 'Registro Inicial') ? 4 : 6}>
              <FormControl
                className={classes.form}
                variant="outlined"
                classes={{ root: classes.root }}
              >
                <InputLabel className={classes.textLabel} id="demo-simple-select-required-label">
                  Tipo *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-required-label"
                  id="demo-simple-select-required"
                  label="Tipo *"
                  required
                  disabled={!!params.tipo}
                  value={typeSelected?.value || null}
                  onChange={(event) => {
                    setProtocol('');
                    setSubject(undefined);
                    setDescription('');
                    setErrorMessage(null);
                    handleCreateType(event.target.value as string);
                  }}
                  classes={{
                    select: classes.menuItem,
                  }}
                  MenuProps={{
                    MenuListProps: {
                      disablePadding: true,
                    },
                  }}
                >
                  {listTypes?.map(({ value, id }) => (
                    <MenuItem
                      className={classes.menuItem}
                      value={value}
                      key={id}
                    >
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {typeSelected && typeSelected?.value !== 'Registro Inicial' && (
            <Grid item xs={4}>
              {loadingProtocol ? <CircularProgress /> : (
                <>
                  <TextField
                    id="outlined-full-width"
                    label="Protocolo"
                    error={(typeof errorMessage) === 'string'}
                    required={typeSelected?.value !== 'Registro Inicial'}
                    fullWidth
                    disabled={!typeSelected?.value || !!params.protocolo}
                    value={protocol}
                    onChange={handleSetProtocol}
                    onBlur={() => getItemWithProtocol(protocol)}
                    variant="outlined"
                    InputLabelProps={{
                      style: {
                        color: '#333',
                      },
                      classes: {
                        root: classes.cssLabel,
                        focused: classes.cssFocused,
                      },
                    }}
                    InputProps={{
                      style: {
                        color: '#333',
                      },
                      classes: {
                        root: classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                      },
                    }}
                  />
                  {typeof errorMessage === 'string' && (
                  <FormHelperText error>
                    {errorMessage}
                  </FormHelperText>
                  )}
                </>
              )}
            </Grid>
            )}
            <Grid item xs={(typeSelected && typeSelected?.value !== 'Registro Inicial') ? 4 : 6}>
              <FormControl
                className={classes.form}
                variant="outlined"
                classes={{ root: classes.root }}
              >
                <InputLabel className={classes.textLabel} id="demo-simple-select-required-label">
                  Assunto *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-required-label"
                  id="demo-simple-select-required"
                  label="Assunto *"
                  required
                  disabled={!typeSelected?.value || completedSubject}
                  value={subject?.titulo ? subject.titulo : ''}
                  onChange={(event) => handleCreateSubject(event.target.value as string)}
                  classes={{
                    select: classes.menuItem,
                  }}
                  MenuProps={{
                    MenuListProps: {
                      disablePadding: true,
                    },
                  }}
                >
                  {listSubjects?.map(({ titulo, id }) => (
                    <MenuItem
                      className={classes.menuItem}
                      value={titulo}
                      key={id}
                    >
                      {titulo}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="outlined-full-width"
                label="Descrição da demanda"
                multiline
                required
                placeholder="Registre aqui"
                fullWidth
                value={description}
                disabled={!!description && (typeSelected.value === 'Recurso' || typeSelected.value === 'Recurso (última instância)')}
                onChange={(event) => setDescription(event.target.value)}
                variant="outlined"
                minRows={5}
                InputLabelProps={{
                  style: {
                    color: '#333',
                  },
                  classes: {
                    root: classes.cssLabel,
                    focused: classes.cssFocused,
                  },
                }}
                InputProps={{
                  style: {
                    color: '#333',
                  },
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                  },
                }}
              />
            </Grid>
            {typeSelected?.value === 'Reclamação' && (
            <Grid item xs={12}>
              <TextField
                id="outlined-full-width"
                label="Reclamação"
                multiline
                required={typeSelected?.value === 'Reclamação'}
                placeholder="Registre aqui sua reclamação"
                fullWidth
                value={complaint}
                onChange={(event) => setComplaint(event.target.value)}
                variant="outlined"
                minRows={5}
                InputLabelProps={{
                  style: {
                    color: '#333',
                  },
                  classes: {
                    root: classes.cssLabel,
                    focused: classes.cssFocused,
                  },
                }}
                InputProps={{
                  style: {
                    color: '#333',
                  },
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                  },
                }}
              />
            </Grid>
            )}
            {typeSelected?.value === 'Pedido Complementar' && (
              <Grid item xs={12}>
                <TextField
                  id="outlined-full-width"
                  label="Complementação"
                  multiline
                  required={typeSelected?.value === 'Pedido Complementar'}
                  placeholder="Registre aqui sua complementação"
                  fullWidth
                  value={complementaryOrder}
                  onChange={(event) => setComplementaryOrder(event.target.value)}
                  variant="outlined"
                  minRows={5}
                  InputLabelProps={{
                    style: {
                      color: '#333',
                    },
                    classes: {
                      root: classes.cssLabel,
                      focused: classes.cssFocused,
                    },
                  }}
                  InputProps={{
                    style: {
                      color: '#333',
                    },
                    classes: {
                      root: classes.cssOutlinedInput,
                      focused: classes.cssFocused,
                    },
                  }}
                />
              </Grid>
            )}
            {(typeSelected?.value === 'Recurso' || typeSelected?.value === 'Recurso (última instância)') && (
            <Grid item xs={12}>
              <TextField
                id="outlined-full-width"
                label="Recurso"
                multiline
                required
                placeholder="Registre aqui seu recurso"
                fullWidth
                value={resource}
                onChange={(event) => setResource(event.target.value)}
                variant="outlined"
                minRows={5}
                InputLabelProps={{
                  style: {
                    color: '#333',
                  },
                  classes: {
                    root: classes.cssLabel,
                    focused: classes.cssFocused,
                  },
                }}
                InputProps={{
                  style: {
                    color: '#333',
                  },
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                  },
                }}
              />
            </Grid>
            )}
            <Grid item xs={12}>
              {files
                  && files.map((file) => (
                    <Box className={classes.document} key={file.file.name}>
                      <Box className={classes.main}>
                        <Box className={classes.contentIcon}>
                          <MdOutlineUploadFile
                            className={classes.documentIcon}
                          />
                        </Box>
                        <Box className={classes.contentInfo}>
                          <Typography className={classes.documentName}>
                            {file?.file?.name}
                          </Typography>
                          <span className={classes.documentLegend}>
                            {`${formatBytes(
                          file.file?.size,
                            )} • Completo`}
                          </span>
                        </Box>
                      </Box>
                      <button
                        type="button"
                        onClick={(): void => removeFile(file)}
                        className={classes.removeDoc}
                      >
                        <MdClose className={classes.removeIcon} />
                      </button>
                    </Box>
                  ))}
              <Box className={classes.boxDropzone}>
                <DropzoneAreaBase
                  onAdd={(filesUpload) => {
                    setFiles(filesUpload);
                  }}
                  fileObjects={files}
                  acceptedFiles={['image/jpeg', 'image/png', '.pdf']}
                  showPreviewsInDropzone={false}
                  dropzoneText="Anexar arquivo"
                  showFileNames={false}
                  showFileNamesInPreview={false}
                  showAlerts={['success', 'error']}
                  filesLimit={30}
                  maxFileSize={31457280}
                  classes={{
                    root: classes.dropzone,
                    icon: classes.dropzoneIcon,
                  }}
                  dropzoneParagraphClass={classes.dropzoneText}
                />
              </Box>
            </Grid>
          </Grid>
          <Box className={classes.boxActions}>
            <span className={classes.warning}>
              * Campo de preenchimento obrigatório
            </span>
            <Box className={classes.boxButton}>
              <button
                type="button"
                onClick={() => controlStep('prev')}
                className={`${classes.button} ${classes.backButton}`}
              >
                <MdArrowBack className={classes.icon} />
                Voltar
              </button>
              <button
                type="submit"
                className={`${classes.button} ${classes.forwardButton}`}
                style={{
                  cursor: (isNotPermittedNextStep || (typeof errorMessage) === 'string') ? 'not-allowed' : 'pointer',
                  opacity:
                  (isNotPermittedNextStep || (typeof errorMessage) === 'string')
                    ? '0.7'
                    : '1',
                }}
                disabled={isNotPermittedNextStep || (typeof errorMessage) === 'string'}
              >
                Avançar
              </button>
            </Box>
          </Box>
        </form>
      ) : (
        <Box
          width="100%"
          height="400px"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
}
