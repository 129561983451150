import React, {
  Suspense, useCallback, useEffect, useState,
} from 'react';
import {
  BrowserRouter,
  Route,
  Switch,
} from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import { RouteLayout, ScrollToTop } from './Components';
import {
  CategoyService,
  Home,
  LoginCallback,
  OrganDetail,
  OrgansList,
  SeachService,
  ServiceInfo,
  MySchedules,
  MyAttendences,
  SchedulePrint,
  NotFound,
  AttendanceOnlineChat,
  Workspace,
  QuickAccess,
  Terms,
  MyInformations,
  CategoryList,
  Accessibility,
  NewManifestation,
  SearchManifestation,
  Onbudsman,
  ServiceList,
} from './Pages';
import WithHeader from './Components/Layouts/WithHeader';
import BlankLayout from './Components/Layouts/BlankLayout';
import ServiceInfoPrint from './Pages/ServiceInfoPrint';
import Reschedule from './Pages/Reschedule';
import ColorContext from './Context/colorContext';
import DocumentValidation from './Pages/DocumentValidation';
import ServiceIntegration from './Pages/ServiceIntegrating';
import ManifestationDetail from './Pages/ManifestationDetail';
import ManifestationPrint from './Pages/ManifestationPrint';
import ManifestationDetailPrint from './Pages/ManifestationDetailPrint';
import FAQ from './Pages/FAQ';
import Demand from './Pages/Demand';
import PrintFromCode from './Pages/PrintFromCode';
import NewEsic from './Pages/NewEsic';
import getCookie from './utils/getCookies';
import DemandPrint from './Pages/DemandPrint';
import DemandMobileRedirect from './Pages/Mobile/Demand/auth';
import DemandWithOutLayout from './Pages/Mobile/Demand/WithOutLayout';

interface ColorContextProps {
  accentColor: string;
  accentColorBackground: string;
  colorBackground: string;
  colorBackgroundSecundary: string;
  textBody: string;
  textAccentColor: string;
  badgeColor: string;
  cardAccentColor: string;
  accordionTextColor: string;
  serviceCardBackgroundColor: string;
  favoriteBoxColor: string;
  breadcrumbButtonColorHover: string;
  borderShadowsColor: string;
  buttonSuccessColor: string;
  buttonErrorColor: string;
  accentColorSecondary: string;
  iconStepColor: string;
  stepLabelColor: string;
  menuItemColor: string;
  subTitleColor: string;
}

function App(): JSX.Element {
  const [typeTheme, setTypeTheme] = useState<string>('normal');

  const handleThemeColor = useCallback((type: string) => {
    document.cookie = `@portalunico/themeColor=${type};path=/`;
    setTypeTheme(type);
  }, []);

  const defaultColors: ColorContextProps = {
    accentColor: process.env.REACT_APP_COLOR_PRIMARY,
    accentColorBackground: process.env.REACT_APP_COLOR_PRIMARY,
    colorBackground: process.env.REACT_APP_COLOR_SECONDARY,
    colorBackgroundSecundary: '#ffffff',
    textBody: '#6B6D6E',
    textAccentColor: '#ffffff',
    badgeColor: process.env.REACT_APP_COLOR_PRIMARY,
    cardAccentColor: process.env.REACT_APP_COLOR_PRIMARY,
    accordionTextColor: '#272727',
    serviceCardBackgroundColor: 'rgba(255, 255, 255, 0.5)',
    favoriteBoxColor: 'rgba(15, 111, 183, 0.05)',
    breadcrumbButtonColorHover: '#d4d4d4',
    borderShadowsColor: '#E1E6EF',
    buttonSuccessColor: '#3CD278',
    buttonErrorColor: '#F03D3D',
    accentColorSecondary: process.env.REACT_APP_COLOR_SECONDARY,
    iconStepColor: '#94C120',
    stepLabelColor: '#ccc',
    menuItemColor: '#d4d4d4',
    subTitleColor: '#706E7A',
  };

  const contrastColors: ColorContextProps = {
    accentColor: '#FFFF1A',
    accentColorBackground: '#1F1F1F',
    colorBackground: '#121212',
    colorBackgroundSecundary: '#1F1F1F',
    textBody: '#FFFF1A',
    textAccentColor: '#FFFF1A',
    badgeColor: '#121212',
    cardAccentColor: '#1F1F1F',
    accordionTextColor: '#FFFF1A',
    serviceCardBackgroundColor: '#1F1F1F',
    favoriteBoxColor: '#1F1F1F',
    breadcrumbButtonColorHover: '#1F1F1F',
    borderShadowsColor: '#FFFF1A',
    buttonSuccessColor: '#FFFF1A',
    buttonErrorColor: '#FFFF1A',
    accentColorSecondary: '#FFFF1A',
    iconStepColor: '#1F1F1F',
    stepLabelColor: '#ccc',
    menuItemColor: '#121212',
    subTitleColor: '#FFFF1A',
  };

  const [colors, setColors] = useState<ColorContextProps>(defaultColors);

  useEffect(() => {
    const themeColors = typeTheme === 'contraste' ? contrastColors : defaultColors;
    setColors(themeColors);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeTheme]);

  useEffect(() => {
    const getThemeLocal = () => {
      const theme = getCookie('@portalunico/themeColor');
      const themeInLocal = theme || 'normal';
      setTypeTheme(themeInLocal);
    };

    getThemeLocal();
  }, []);

  return (
    <ColorContext.Provider value={{ colors, setTypeTheme: handleThemeColor, typeTheme }}>
      <BrowserRouter>
        <Suspense fallback={(
          <div
            style={{
              display: 'flex', width: '100%', height: '100vh', alignItems: 'center', justifyContent: 'center',
            }}
          >
            <CircularProgress size={50} color="primary" />
          </div>
        )}
        >
          <ScrollToTop />
          <Switch>
            <RouteLayout
              path="/"
              exact
              component={Home}
              layout={WithHeader}
            />
            <RouteLayout
              path="/buscar/q=:slug"
              exact
              component={SeachService}
              layout={WithHeader}
            />
            <RouteLayout
              path="/servico/:slug"
              exact
              component={ServiceInfo}
              layout={WithHeader}
            />
            <Route
              path="/servico/:slug/imprimir"
              exact
              component={ServiceInfoPrint}
            />
            <RouteLayout
              path="/servico/:slug/presencial=:modal"
              exact
              component={ServiceInfo}
              layout={WithHeader}
            />
            <RouteLayout
              path="/servico-integrado/:slug"
              exact
              component={ServiceIntegration}
              layout={WithHeader}
            />
            <RouteLayout
              path="/servicos"
              exact
              component={ServiceList}
              layout={WithHeader}
            />
            <RouteLayout
              path="/categoria/:slug"
              exact
              component={CategoyService}
              layout={WithHeader}
            />
            <RouteLayout
              path="/categorias"
              exact
              component={CategoryList}
              layout={WithHeader}
            />
            <RouteLayout
              path="/meus_agendamentos"
              exact
              component={MySchedules}
              layout={WithHeader}
              isAuth
            />
            <RouteLayout
              path="/meus_atendimentos"
              exact
              component={MyAttendences}
              layout={WithHeader}
              isAuth
            />
            <Route path="/login/callback/" component={LoginCallback} />
            {/* <RouteLayout exact path="/videos" layout={WithHeader} component={VideoList} /> */}
            <RouteLayout exact path="/orgaos" layout={WithHeader} component={OrgansList} />
            <RouteLayout exact path="/orgao/:slug" layout={WithHeader} component={OrganDetail} />
            <RouteLayout exact path="/acessibilidade" layout={WithHeader} component={Accessibility} />
            <Route exact path="/agendamento/comprovante/:id" component={SchedulePrint} />
            <RouteLayout exact path="/validacao_documento/:hash?/:id?" layout={WithHeader} component={DocumentValidation} />
            <RouteLayout
              exact
              path="/atendimento_online/:id"
              layout={WithHeader}
              component={AttendanceOnlineChat}
            />
            {/* <RouteLayout exact path="/noticias" layout={WithHeader} component={News} /> */}
            {/* <RouteLayout exact path="/noticias/:slug" layout={WithHeader} component={newDetail} /> */}
            <RouteLayout exact path="/ouvidoria" layout={WithHeader} component={Onbudsman} />
            <RouteLayout exact path="/ouvidoria/perguntas_frequentes" layout={WithHeader} component={FAQ} />
            <RouteLayout exact path="/manifestacao/:slug" layout={WithHeader} component={NewManifestation} />
            <RouteLayout exact path="/consultar_manifestacao" layout={WithHeader} component={SearchManifestation} />
            <RouteLayout exact path="/esic/:protocolo?/:tipo?" layout={WithHeader} component={NewEsic} />
            <RouteLayout exact path="/detalhes_manifestacao/numero=:protocolo&codigo=:codigo" layout={WithHeader} component={ManifestationDetail} />
            <Route exact path="/comprovante/agendamento/:ticket" component={PrintFromCode} />
            <RouteLayout exact path="/demanda/:slug" layout={WithHeader} component={Demand} />
            <RouteLayout exact path="/demanda/mobile/:slug" layout={BlankLayout} component={DemandWithOutLayout} />
            <RouteLayout exact path="/demanda/mobile/:slug/receive_token/:tokenSSO" layout={BlankLayout} component={DemandMobileRedirect} />

            <Route
              path="/manifestacao/:protocolo/imprimir"
              exact
              component={ManifestationPrint}
            />
            <Route
              path="/demanda/:protocolo/imprimir"
              exact
              component={DemandPrint}
            />
            <Route
              path="/manifestacao/:codigo/:protocolo/imprimir"
              exact
              component={ManifestationPrint}
            />
            <Route
              path="/detalhes_manifestacao/:codigo/:protocolo/imprimir"
              exact
              component={ManifestationDetailPrint}
            />
            <RouteLayout
              exact
              path="/workspace"
              isAuth
              layout={BlankLayout}
              component={Workspace}
            />
            <RouteLayout
              exact
              path="/workspace/termos"
              isAuth
              layout={BlankLayout}
              component={Workspace}
            />
            <RouteLayout
              exact
              path="/workspace/permissoes"
              isAuth
              layout={BlankLayout}
              component={Workspace}
            />

            <RouteLayout
              exact
              path="/workspace/atividades"
              isAuth
              layout={BlankLayout}
              component={Workspace}
            />
            <RouteLayout
              exact
              path="/workspace"
              isAuth
              layout={BlankLayout}
              component={Workspace}
            />
            <RouteLayout
              exact
              path="/workspace/privacidade"
              isAuth
              layout={BlankLayout}
              component={Workspace}
            />
            <RouteLayout
              exact
              path="/workspace/meus-dados"
              isAuth
              layout={BlankLayout}
              component={Workspace}
            />
            <RouteLayout
              exact
              path="/workspace/favoritos"
              isAuth
              layout={BlankLayout}
              component={Workspace}
            />

            <RouteLayout
              exact
              path="/workspace/servico-digital"
              isAuth
              layout={BlankLayout}
              component={Workspace}
            />

            <RouteLayout
              exact
              path="/workspace/formularios/:slug/:protocolo?"
              isAuth
              layout={BlankLayout}
              component={Workspace}
            />

            <RouteLayout
              exact
              path="/workspace/servico-digital/:tema_slug/:tema"
              isAuth
              layout={BlankLayout}
              component={Workspace}
            />
            <RouteLayout
              exact
              path="/workspace/atendimento_online/:id"
              isAuth
              layout={BlankLayout}
              component={Workspace}
            />
            <RouteLayout
              exact
              path="/workspace/mobile/atendimento_online/:id/receive_token/:tokenSSO"
              layout={BlankLayout}
              component={Workspace}
            />
            <RouteLayout
              exact
              path="/workspace/mobile/atendimento_online/:id"
              layout={BlankLayout}
              component={Workspace}
            />

            <RouteLayout
              exact
              path="/workspace/mobile/formularios/:slug/:protocolo?"
              layout={BlankLayout}
              component={Workspace}
            />

            <RouteLayout
              exact
              path="/workspace/formularios/mobile/:slug/receive_token/:tokenSSO"
              layout={BlankLayout}
              component={Workspace}
            />

            <RouteLayout
              exact
              path="/workspace/seguranca"
              isAuth
              layout={BlankLayout}
              component={Workspace}
            />
            <RouteLayout
              path="/workspace/meus_servicos/view_doc/:uuid_doc/:identificador_solcitacao"
              exact
              isAuth
              component={Workspace}
              layout={BlankLayout}
            />
            <RouteLayout
              path="/workspace/meus_servicos/mobile/view_doc/:uuid_doc/:identificador_solcitacao"
              exact
              isAuth
              component={Workspace}
              layout={BlankLayout}
            />
            <RouteLayout
              exact
              path="/workspace/avaliar_atendimento_presencial/:ticket"
              layout={BlankLayout}
              component={Workspace}
            />
            <RouteLayout
              path="/workspace/mobile/receive_token/:tokenSSO/:uuid_doc/:identificador_solcitacao"
              exact
              component={Workspace}
              layout={BlankLayout}
            />
            <RouteLayout
              path="/workspace/minhas_solicitacoes"
              exact
              isAuth
              component={Workspace}
              layout={BlankLayout}
            />
            <RouteLayout
              exact
              path="/workspace/minhas_solicitacoes/:tipo/:protocolo"
              layout={BlankLayout}
              component={Workspace}
            />
            <RouteLayout
              exact
              path="/workspace/avaliar_atendimento_interno/:ticket"
              layout={BlankLayout}
              component={Workspace}
            />

            <RouteLayout
              path="/workspace/avaliar_atendimento_online/:ticket"
              exact
              layout={BlankLayout}
              component={Workspace}
            />
            <RouteLayout
              path="/workspace/avaliar/:ticket"
              exact
              layout={BlankLayout}
              component={Workspace}
            />

            <RouteLayout
              exact
              path="/workspace/avaliar_atendimento_diario/:ticket"
              layout={BlankLayout}
              component={Workspace}
            />

            <RouteLayout
              path="/workspace/avaliar_esic/:id"
              exact
              layout={BlankLayout}
              component={Workspace}
            />

            <RouteLayout
              path="/workspace/avaliar_manifestacao/:id"
              exact
              layout={BlankLayout}
              component={Workspace}
            />

            <RouteLayout
              path="/workspace/avaliar_fluxo/:id"
              exact
              layout={BlankLayout}
              component={Workspace}
            />
            <RouteLayout
              path="/workspace/avaliar_solicitacao_servico/:id"
              exact
              layout={BlankLayout}
              component={Workspace}
            />
            <RouteLayout
              path="/workspace/notificacoes"
              exact
              component={Workspace}
              layout={BlankLayout}
            />
            <RouteLayout
              exact
              path="/acesso-rapido"
              isAuth
              layout={WithHeader}
              component={QuickAccess}
            />
            <RouteLayout
              exact
              path="/termos"
              isAuth
              layout={WithHeader}
              component={Terms}
            />
            <RouteLayout
              exact
              path="/meus_dados"
              isAuth
              layout={WithHeader}
              component={MyInformations}
            />
            <RouteLayout
              path="/reagendamentos/:url"
              exact
              component={Reschedule}
              layout={BlankLayout}
            />
            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </BrowserRouter>
    </ColorContext.Provider>
  );
}

export default App;
